import React, { useState, useEffect } from 'react';
import "./App.css"
import ABI from "./ABI";
import ERC20ABI from "./ERC20ABI.json"
import Contract from './ABI';

const App = () => {
return(
  <div>Hi</div>
)
}

export default App;
